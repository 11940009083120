import * as React from "react"
import { graphql } from "gatsby"
import { Layout, PrismicSlice } from '../components'
import { AlbumContainer } from '../containers'
import AlbumInteractions from "../components/AlbumInteractions"

class Album extends React.Component {
  render() {
    const { 
      location: {
        pathname
      },
      data: {
        prismicAlbums: {
          data: {
            title,
            cover,
            description,
            bandcamp,
            spotify,
            applemusic,
            more_links,
            body,
            seo_title,
            seo_description,
            ogimage
          }
        }
      }
    } = this.props;
    
    return (
      <Layout 
        customBackground={cover.localFile} 
        cover
        context={{
          title: seo_title.text,
          description: seo_description.text,
          ogimage: ogimage.localFile.publicURL,
          slug: pathname,
        }}
      >
        <div>
          <AlbumContainer>
            <div>
              <div>
                <div>
                  <img 
                    src={cover.localFile.publicURL}
                    alt={cover.alt}
                  />
                </div>
              </div>
              <div>
                <h1>{title.text}</h1>
                <div dangerouslySetInnerHTML={{ __html: description.html }}></div>
                <div className="links">
                  <AlbumInteractions {...{
                    bandcamp: bandcamp.url,
                    spotify: spotify.url,
                    applemusic: applemusic.url,
                    otherLinks: more_links,
                  }} />
                </div>
              </div>
            </div>
          </AlbumContainer>
          <PrismicSlice body={body} />
        </div>
      </Layout>
    )
  }
}

export default Album;
export const pageQuery = graphql`
  query currentAlbum($slug: String!) {
    prismicAlbums(uid: { eq: $slug }) {
      uid
      data {
        seo_title {
          text
        }
        ogimage {
          localFile {
            publicURL
          }
        }
        seo_description {
          text
        }
        title {
          text
        }
        cover {
          localFile {
            publicURL
            uid
          }
          alt
        }
        description {
          html
        }
        bandcamp {
          url
        }
        spotify {
          url
        }
        applemusic {
          url
        }
        more_links {
          label
          link {
            url
          }
        }
        body {
          ... on PrismicAlbumsDataBodyTexte {
            primary {
              texte {
                html
              }
            }
          }
          ... on PrismicAlbumsDataBodyVideo {
            id
            primary {
              video {
                document {
                  ... on PrismicVideo {
                    id
                    data {
                      video {
                        embed_url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAlbumsDataBodyVideos {
            id
            primary {
              videos {
                document {
                  ... on PrismicVideoscollection {
                    id
                    data {
                      items {
                        item {
                          document {
                            ... on PrismicVideo {
                              id
                              data {
                                video {
                                  embed_url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`